import { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { usePapaParse } from "react-papaparse";
import Category from "./Category";
import Header from "./Header";
import Result from "./Result";

function Main() {
  // eslint-disable-next-line
  const [headers, setHeaders] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [sponsorData, setSponsorData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // eslint-disable-next-line
  const [filteredSponsorData, setFilteredSponsorData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("all");
  const { readRemoteFile } = usePapaParse();

  const readGoogleSheet = useCallback(() => {
    readRemoteFile(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRjc2pMfujBD505mI1Sb0H7NLXOBvway8kRYu-OoWIKbNf52tASajcbS5waJ4El0PoLc9-qR71rNxLj/pub?gid=1905648783&single=true&output=csv",
      {
        complete: (results) => {
          const resHeaders = results.data[0];
          setHeaders(resHeaders);

          const resCategories = results.data[0].slice(5);
          resCategories.sort();
          setCategories([...new Set(resCategories)]);

          const resData = results.data.slice(1);

          const newData = [];
          resData.forEach((data) => {
            let i = 0;
            const resObj = {
              name: "",
              website: "",
              description: "",
              categories: [],
            };
            data.forEach((d) => {
              if (i === 0) {
                resObj.name = d.trim();
              } else if (i === 1) {
                resObj.website = d.trim();
              } else if (i === 2) {
                resObj.description = d.trim();
              } else if (i > 4) {
                if (d) {
                  resObj.categories.push(resHeaders[i].trim());
                }
              }

              if (i === data.length - 1) {
                newData.push(resObj);
              }
              i += 1;
            });
          });

          setSheetData(newData);
        },
      }
    );
  }, [readRemoteFile]);

  const readGoogleSheetSponsor = useCallback(() => {
    readRemoteFile(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRjc2pMfujBD505mI1Sb0H7NLXOBvway8kRYu-OoWIKbNf52tASajcbS5waJ4El0PoLc9-qR71rNxLj/pub?gid=614132911&single=true&output=csv",
      {
        complete: (results) => {
          const resData = results.data.slice(1);

          const newData = [];
          resData.forEach((data) => {
            let i = 0;
            const resObj = {
              name: "",
              category: "",
              platinum_sponsor: "",
              gold_sponsor: "",
              silver_sponsor: "",
              video_url: "",
              placeholder_image: "",
              sponsor_logo: "",
              cta_button_title: "",
              description_image: "",
              description_heading: "",
              category_description: "",
              rating: "",
              reviews_count: "",
              border_sponsor_logo: "",
              border_description_image: "",
            };
            data.forEach((d) => {
              if (i === 0) {
                resObj.name = d.trim();
              } else if (i === 1) {
                resObj.category = d.trim();
              } else if (i === 2) {
                resObj.platinum_sponsor = d.trim();
              } else if (i === 3) {
                resObj.gold_sponsor = d.trim();
              } else if (i === 4) {
                resObj.silver_sponsor = d.trim();
              } else if (i === 5) {
                resObj.video_url = d.trim();
              } else if (i === 6) {
                resObj.placeholder_image = d.trim();
              } else if (i === 7) {
                resObj.sponsor_logo = d.trim();
              } else if (i === 8) {
                resObj.cta_button_title = d.trim();
              } else if (i === 9) {
                resObj.description_image = d.trim();
              } else if (i === 10) {
                resObj.description_heading = d.trim();
              } else if (i === 11) {
                resObj.category_description = d.trim();
              } else if (i === 12) {
                resObj.rating = d.trim();
              } else if (i === 13) {
                resObj.reviews_count = d.trim();
              } else if (i === 18) {
                if (d.trim()) {
                  resObj.border_sponsor_logo = "YES";
                }
              } else if (i === 19) {
                if (d.trim()) {
                  resObj.border_description_image = "YES";
                }
              }

              if (i === data.length - 1) {
                newData.push(resObj);
              }
              i += 1;
            });
          });

          setSponsorData(newData);
        },
      }
    );
  }, [readRemoteFile]);

  const readGoogleSheetCategory = useCallback(() => {
    readRemoteFile(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRjc2pMfujBD505mI1Sb0H7NLXOBvway8kRYu-OoWIKbNf52tASajcbS5waJ4El0PoLc9-qR71rNxLj/pub?gid=516963400&single=true&output=csv",
      {
        complete: (results) => {
          const resData = results.data.slice(1);

          const newData = [];
          resData.forEach((data) => {
            let i = 0;
            const resObj = {
              category: "",
              description: "",
            };
            data.forEach((d) => {
              if (i === 0) {
                resObj.category = d.trim();
              } else if (i === 1) {
                resObj.description = d.trim();
              }

              if (i === data.length - 1) {
                newData.push(resObj);
              }
              i += 1;
            });
          });

          setCategoryData(newData);
        },
      }
    );
  }, [readRemoteFile]);

  useEffect(() => {
    readGoogleSheet();
    readGoogleSheetSponsor();
    readGoogleSheetCategory();
  }, [readGoogleSheet, readGoogleSheetSponsor, readGoogleSheetCategory]);

  const handleCategoryChange = (catObj) => {
    const cat = catObj.value;
    setCategory(cat.trim());

    const newData2 = sponsorData.filter((val) => val.category.trim() === cat);
    setFilteredSponsorData(newData2);

    const newData = sheetData.filter((val) =>
      val.categories.includes(cat.trim())
    );

    //if (newData2.length > 0) {
    const mixedData = [];
    newData.forEach((val) => {
      const matchedData = newData2.find(function (e) {
        return e.name === val.name;
      });

      if (matchedData !== undefined) {
        val.video_url = matchedData.video_url;
        val.placeholder_image = matchedData.placeholder_image;
        val.sponsor_logo = matchedData.sponsor_logo;
        val.cta_button_title = matchedData.cta_button_title;
        val.description_image = matchedData.description_image;
        val.description_heading = matchedData.description_heading;
        val.category_description = matchedData.category_description;
        val.rating = matchedData.rating;
        val.reviews_count = matchedData.reviews_count;
        val.border_sponsor_logo = matchedData.border_sponsor_logo;
        val.border_description_image = matchedData.border_description_image;

        if (matchedData.platinum_sponsor) {
          val.sponsor_index = 1;
        } else if (matchedData.gold_sponsor) {
          val.sponsor_index = 2;
        } else if (matchedData.silver_sponsor) {
          val.sponsor_index = 3;
        }

        mixedData.push(val);
      } else {
        val.sponsor_index = 99;
        mixedData.push(val);
      }
    });

    const sortedData = mixedData.sort((a, b) => {
      return a.sponsor_index - b.sponsor_index;
    });

    setFilteredData(sortedData);
    // } else {
    //   setFilteredData(newData);
    // }

    window.dataLayer.push({
      event: "partner-category-filter",
      partnerCategory: cat,
    });
  };

  return (
    <div className="wrapper">
      <div className="header">
        <Header />
        {categories.length === 0 ? (
          <SkeletonTheme baseColor="#FFF" highlightColor="#DDD">
            <Skeleton style={{ width: 300, height: 40, marginTop: 20 }} />
          </SkeletonTheme>
        ) : (
          <Category
            category={category}
            categories={categories}
            sheetData={sheetData}
            filteredData={filteredData}
            handleCategoryChange={handleCategoryChange}
            categoryData={categoryData}
          />
        )}
      </div>
      <div className="main">
        {sheetData.length === 0 ? (
          <SkeletonTheme baseColor="#FFF" highlightColor="#DDD">
            <Skeleton
              count={12}
              style={{ width: "95%", height: 75, margin: 10, padding: 10 }}
            />
          </SkeletonTheme>
        ) : (
          <Result
            category={category}
            sheetData={sheetData}
            filteredData={filteredData}
          />
        )}
      </div>
    </div>
  );
}

export default Main;
