import cancel_icon from "../assets/cancel_icon.png";

function Modal({ type, handleClose, show, children }) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <div className={`modal-main ${type === "iframe" ? "modal-iframe" : ""}`}>
        {children}
        <img
          src={cancel_icon}
          alt="Cancel Modal"
          onClick={handleClose}
          className="cancel-modal"
        />
      </div>
    </div>
  );
}

export default Modal;
