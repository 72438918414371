function Header() {
    return (
        <>
            <h1>View Business Partner List</h1>
            <p>
                So whatever your Revenue Cycle needs may be, one of the business partners listed has
                a solution for you. Contact them today to learn more about how they can help you
                optimize your Revenue Cycle. Let them know Revenue Cycle Resources sent you!
            </p>
        </>
    );
}

export default Header;
